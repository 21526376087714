import { http } from '@/utils/http';
import { RequestMethodEnum } from '@/utils/http/HttpEnum';

/**
 * 获取openId
 * @param {String} code
 */
export const getOpenId = (code: string) => {
  return http.request({
    url: '/auth/getWxOpenId',
    method: RequestMethodEnum.POST,
    data: { code },
  });
};

/**
 * 获取支付配置
 * @param data
 */
export const getPayConfig = (data: any) => {
  return http.request({
    url: '/scan',
    method: RequestMethodEnum.POST,
    data,
  });
};

/**
 * 获取订单详情
 * @param params
 */
export const getOrderDetail = (params: any) => {
  return http.request({
    url: '/mer/detail',
    method: RequestMethodEnum.GET,
    params,
  });
};
