import { createSSRApp } from 'vue';
import App from './App.vue';
import TnIcon from '@tuniao/tnui-vue3-uniapp/components/icon/src/icon.vue';
import TnNavbar from '@tuniao/tnui-vue3-uniapp/components/navbar/src/navbar.vue';
import TnButton from '@tuniao/tnui-vue3-uniapp/components/button/src/button.vue';
import store from './store';

import 'virtual:uno.css';

export function createApp() {
  const app = createSSRApp(App);

  app.use(store);

  // 注册全局组件
  app.component('TnIcon', TnIcon);
  app.component('TnNavbar', TnNavbar);
  app.component('TnButton', TnButton);

  // 对 App.vue onLaunch 事件 异步改 同步
  app.config.globalProperties.$onLaunch = new Promise((resolve) => {
    app.config.globalProperties.$isResolve = resolve;
  });

  return {
    app,
  };
}
